<template>
  <div class="alert" :class="['alert-'+type]">
    <slot/>
  </div>
</template>

<script>
export default {
  props: ['type'],
};
</script>

<style lang="scss" scoped>
  .alert {

    border-radius: 2px;
    padding: 15px;
    color: #fff;
    margin-bottom: 15px;

    &-danger {
      background-color: #ff4f26;
    }

    &-success {
      background-color: #21d04f;
    }
  }
</style>
