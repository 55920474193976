<template>
  <Alert type="danger" v-if="formattedErrors.length" class="validation-errors">
    <ul>
      <li v-for="(error, i) in formattedErrors" :key="i">
        {{ error}}
      </li>
    </ul>
  </Alert>
</template>

<script>
import Alert from '@/modules/core/views/components/Alert';

export default {
  components: { Alert },
  props: ['errors'],
  computed: {
    formattedErrors() {
      let list = [];

      Object.values(this.errors).forEach(errors => {
        errors.forEach(error => {
          list.push(error);
        });
      });

      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
  .validation-errors {

    ul {
      margin: 0;
      padding-left: 20px;
      list-style-type: circle;
    }
  }
</style>
