<template>
  <div class="form-builder">
    <div class="relative grid grid-cols-6 gap-6 mb-4">
      <div
        :class="[
          { 'input-error': errors && !!errors[field.name] },
          `col-span-${field.colSpan || field.colspan || 6} flex flex-col`
        ]"
        v-for="(field, idx) in fields"
        :key="idx"
        :style="{ 'z-index': fields.length - idx }"
      >

        <section v-if="field.type === 'section'">
          <h2 v-if="field.title" class="text-xl font-semibold text-prasset-green-500">{{ field.title }}</h2>
          <MarkdownContent v-if="field.content" class="text-sm text-prasset-gray-800 mt-2" :content="field.content"></MarkdownContent>
        </section>

        <label
          v-if="!['check', 'checkbox', 'radio', 'radiobutton', 'section'].includes(field.type)"
          class="text-sm text-prasset-green-800 mb-2 block"
          :for="`input_${field.name}`"
        >
          {{ field.label }}
        </label>

        <TextField
          v-if="['text', 'email', 'number', 'tel'].includes(field.type)"
          :id="`input_${field.name}`"
          :type="field.type"
          :placeholder="field.placeholder"
          :name="field.name"
          :error="errors[field.name]"
          :value="form[field.name]"
          :readonly="field.readonly || false"
          v-model="form[field.name]"
        />

        <TextareaField
          v-if="field.type === 'textarea'"
          :id="`input_${field.name}`"
          :rows="field.rows"
          :type="field.type"
          :placeholder="field.placeholder"
          :name="field.name"
          :error="errors[field.name]"
          :value="form[field.name]"
          :readonly="field.readonly || false"
          v-model="form[field.name]"
        />

        <DateField
          v-if="field.type === 'date'"
          :name="field.name"
          :value="form[field.name]"
          :placeholder="field.placeholder"
          v-model="form[field.name]"
          :config="field.config || {}"
        />

        <DateRangeField
          v-if="field.type === 'date-range'"
          :name="field.name"
          :placeholder="field.placeholder"
          v-model="form[field.name]"
          :config="field.config || {}"
        />

        <ToggleField
          v-if="field.type === 'toggle'"
          :name="field.name"
          :value="form[field.name]"
          :placeholder="field.placeholder"
          v-model="form[field.name]"
        />

        <CheckField
          v-if="['check', 'checkbox'].includes(field.type)"
          :name="field.name"
          :value="form[field.name]"
          v-model="form[field.name]"
        >{{ field.label }}</CheckField>

        <template v-if="['radio', 'radiobutton'].includes(field.type)">
          <RadioField
            v-for="(label, key) in field.options"
            :key="key"
            :name="field.name"
            :value="key"
            v-model="form[field.name]"
            class="mb-1"
          >{{ label }}</RadioField>
        </template>

        <HtmlField
          v-if="field.type === 'html'"
          :name="field.name"
          :placeholder="field.placeholder"
          v-model="form[field.name]"
          :disabled="field.disabled"
        />

        <FilesField
          v-if="field.type === 'files'"
          :name="field.name"
          :entryType="computedEntryType"
          :entryId="computedEntryId"
          v-model="form[field.name]"
          :disabled="field.disabled"
          :collection="field.name"
          :config="field.config"
          :fileCollection="form.media"
        />

        <SelectField
          v-if="field.type === 'select'"
          :name="field.name"
          :value="form[field.name]"
          v-model="form[field.name]"
          :options="field.options || null"
          :endpoint="fieldEndpoint(field)"
          :error="errors[field.name]"
          :disabled="!(field.nullable || true)"
        />

        <MultiSelectField
          v-if="field.type === 'multiselect'"
          :name="field.name"
          v-model="form[field.name]"
          :options="field.options || null"
          :placeholder="field.placeholder"
          :endpoint="fieldEndpoint(field)"
          :error="errors[field.name]"
          :disabled="!(field.nullable || true)"
        />

        <ColorField
          v-if="field.type === 'color'"
          :name="field.name"
          :value="form[field.name]"
          v-model="form[field.name]"
          :config="field.config || {}"
        />

        <MarkdownField
          v-if="field.type === 'markdown'"
          :name="field.name"
          :value="form[field.name]"
          v-model="form[field.name]"
          :config="field.config || {}"
        />

        <LinkField
          v-if="field.type === 'link'"
          :name="field.name"
          :error="errors[field.name]"
          :config="field.config || {}"
          :value="form[field.name]"
          v-model="form[field.name]"
        />

        <div v-if="field.hint" class="hint">
          {{ field.hint }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
tailwind-purge:
col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6
*/

import CheckField from '@/components/field/Check';
import ColorField from '@/components/field/Color';
import DateField from '@/components/field/Date';
import DateRangeField from '@/components/field/DateRange';
import FilesField from '@/components/field/Files';
import HtmlField from '@/components/field/HtmlEditor';
import LinkField from '@/components/field/Link';
import MarkdownContent from '@/components/MarkdownContent';
import MarkdownField from '@/components/field/Markdown';
import MultiSelectField from '@/components/field/MultiSelect';
import RadioField from '@/components/field/Radio';
import SelectField from '@/components/field/Select';
import TextareaField from '@/components/field/Textarea';
import TextField from '@/components/field/Text';
import ToggleField from '@/components/field/Toggle';

export default {
  components: {
    CheckField,
    ColorField,
    DateField,
    DateRangeField,
    FilesField,
    HtmlField,
    LinkField,
    MarkdownContent,
    MarkdownField,
    MultiSelectField,
    RadioField,
    SelectField,
    TextareaField,
    TextField,
    ToggleField,
  },

  props: {
    fields: {
      type: Array,
    },

    errors: {
      type: [Array, Object],
      default: null,
    },

    entryType: {
      type: String,
      default: null,
    },

    entryId: {
      type: String,
      default: null,
    },

    form: {
      type: Object,
      default: () => {
        return {};
      },
    },

    modelValue: {
      default: undefined,
    },
  },

  watch: {
    data: {
      deep: true,
      handler(data) {
        this.form = data;
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.$emit('input', data);
      },
    },
  },

  mounted() {
    if (process.env.NODE_ENV === 'development') {
      console.warn('[DEPRICATED] FormGenerator is depricated, use FieldsGenerator instead.');
    }
  },

  computed: {
    computedEntryType() {
      return this.entryType ?
        this.entryType :
        this.$route.params.type;
    },

    computedEntryId() {
      return this.entryId ?
        this.entryId :
        this.$route.params.component_id;
    },

    fieldEndpoint() {
      return field => {
        if (field.endpoint) {
          return `projects/${this.$route.params.project_id}/${field.endpoint}`;
        }
        return undefined;
      };
    },
  },
};
</script>
