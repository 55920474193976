<template>
  <div>
    <form @submit.prevent="submit()" class="p-6 mx-auto max-w-lg">
      <header class="mb-10">
        <h1 class="text-xl mb-2 text-prasset-green-500">Integraties</h1>
        <p class="text-gray-600">Selecteer externe bronnen om mee te koppelen.</p>
      </header>

      <div v-if="providers" class="mt-4">
        <div v-for="provider in providers" :key="provider.name">
          <CheckField @change="resetProvider(provider.name)" :value="true" v-model="provider.checked" class="mb-4">
            {{ provider.name }}
          </CheckField>
          <validation-error-message :errors="provider.errors"/>
          <FormGenerator v-if="provider.checked" v-model="data[provider.name]" :errors="provider.errors" :fields="provider.fields" />
        </div>
      </div>

      <div class="mt-10 container flex space-between">
        <router-link :to="{name: 'dashboard'}" class="button button--outlined mr-4">
          Overslaan
        </router-link>
        <button class="button button--opague" type="submit">
          Toevoegen
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { asyncForEach } from '@/providers/helpers';
import Api from '@/providers/api';
import CheckField from '@/components/field/Check';
import FormGenerator from '@/modules/core/views/components/FormGenerator';
import ValidationErrorMessage from '@/modules/core/views/components/ValidationErrorMessage';

export default {
  components: {
    CheckField,
    FormGenerator,
    ValidationErrorMessage,
  },

  mounted() {
    this.getConnections();
  },

  data() {
    return {
      providers: null,
      data: {},
    };
  },

  methods: {
    resetProvider(providerName) {
      this.providers = this.providers.map(provider => {
        if (provider.name === providerName) {
          provider.errors = {};
        }

        return provider;
      });
    },

    getConnections() {
      Api().get(`projects/${this.$route.params.project_id}/connections/providers`).then(({ data }) => {
        this.providers = data.data.map(row => {
          this.data[row.name] = {};

          row.checked = false;
          row.credentials = {};
          row.errors = {};

          row.fields = Object.entries(row.fields).map(entry => {
            return {
              type: 'text',
              colSpan: 6,
              ...entry[1],
            };
          });

          return row;
        });
      });
    },

    async submit() {
      let failed = false;

      await asyncForEach(this.providers, async (provider) => {
        if (provider.checked) {
          let form = {
            provider: provider.name,
            name: provider.name,
            credentials: {},
            options: {},
          };

          await Object.entries(this.data[provider.name]).forEach(entry => {
            const name = entry[0].split('.')[1];
            const value = entry[1];
            form.credentials[name] = value;
            form.options[name] = value;
          });

          try {
            await Api().post(`projects/${this.$route.params.project_id}/connections`, form);
          } catch ({ response }) {
            failed = true;
            const data = response.data;
            this.providers = this.providers.map(prov => {
              if (prov.name === provider.name) {
                prov.errors = data.errors ? data.errors : { message: [data.message] };
              }
              return prov;
            });
          }

        }
      });

      if (!failed) {
        this.$router.push({ name:'dashboard' });
      }
    },
  },
};
</script>
